





































































































































































































































import { Prop, Component, Vue } from 'vue-property-decorator'
import { FileCompactViewModel } from '@/views/Group/TopPage/SharedFolder/Layout/SharedFolderLayout.vue'
import { EFileFormat, EUserStatus } from '@/models'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import FormatDate from '@/helpers/FormatDate'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
//@ts-ignore
import _ from 'lodash'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'

const CommonTopicModule = getModule(CommonTopic, store)

@Component
export default class SharedFolderList extends Vue {
  @Prop() private files!: FileCompactViewModel[]
  @Prop() private startLoading!: boolean
  @Prop() private disableCheckbox?: boolean
  @Prop() private forGroupTop?: boolean
  @Prop() private isFromHome?: boolean
  @Prop() private isHomeCommon?: boolean
  @Prop() private groupId?: string
  private isCommonTopic = !this.$route.params.groupId
  private eFileFormat = EFileFormat
  private noGroupAvatar = require('@/assets/images/group/avatar_default_1.png')
  private commonTopicAvatar = require('@/assets/images/logo-cs-1.png')

  handleCheckItem() {
    if (this.files.every(item => item.checked)) {
      this.$emit('addCheckAll')
    } else {
      this.$emit('removeCheckAll')
    }
    this.$emit(
      'selectAnyItem',
      this.files.some(item => item.checked)
    )
  }

  handleMarkRead(index: number) {
    this.$blockui.show()
    if ((this.isCommonTopic && !this.isFromHome) || this.isHomeCommon) {
      CommonSharedFolderService.markCommonFileAsRead(this.files[index].id)
        .then(res => {
          if (res.status === 200) {
            this.files[index].is_read = true
            this.$emit('subtractReadCount')
            //handle rebind unred count
            CommonTopicModule.SET_RELOAD_UNREAD(true)
          }
        })
        .catch(err => {
          // this.$bvModal.show('modal-error-shared-folder-list')
        })
        .finally(() => this.$blockui.hide())
    } else {
      GroupSharedFolderService.markFileAsRead(
        this.isFromHome ? String(this.groupId) : this.$route.params.groupId,
        this.files[index].id
      )
        .then(res => {
          if (res.status === 200) {
            this.files[index].is_read = true
            this.$emit('subtractReadCount')
            //handle rebind unred count
            CommonTopicModule.SET_RELOAD_UNREAD(true)
          }
        })
        .catch(err => {
          // this.$bvModal.show('modal-error-shared-folder-list')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle jumping to topic detail page
   */
  jumpToFileDetailPage(index: number, push?: boolean) {
    const fileId = this.files[index].id
    let route: any
    if ((this.isCommonTopic && !this.isFromHome) || this.isHomeCommon) {
      route = {
        name: 'common-topic-shared-folder-file-detail',
        params: { fileId: String(fileId), from: this.$route.path }
      }
    } else {
      route = {
        name: 'group-shared-folder-file-detail',
        params: {
          fileId: String(fileId),
          groupId: this.isFromHome
            ? String(this.groupId)
            : this.$route.params.groupId,
          folder: this.$route.query.folder
            ? String(this.$route.query.folder)
            : '',
          from: this.$route.path
        }
      }
    }
    if (push) {
      this.$router.push(route)
    } else {
      return route
    }
  }

  getFileFormat(fileExtension: string) {
    return CheckFileFormat.getFileFormat(fileExtension)
  }

  getIconName(fileExtension: string) {
    return CheckFileFormat.getIconClassName(
      CheckFileFormat.getFileFormat(fileExtension)
    )
  }

  formatDateWithDays(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  handleLongText(text: string) {
    return _.truncate(text, {
      length: 50,
      separator: ' '
    })
  }

  handleUpdaterName(file: FileCompactViewModel) {
    let text = this.hanldeUserStatus(
      file.updater?.name,
      file.user_active_status
    )
    return {
      name: text,
      text: _.truncate(text, {
        length: 20,
        separator: ' '
      })
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }
}
