var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[(!_vm.topics.length && !_vm.startLoading)?_c('div',{staticClass:"text-center text-secondary pt-6"},[_vm._v(" "+_vm._s(_vm.$t("common.message.no_data"))+" ")]):_vm._e(),_vm._l((_vm.topics),function(topic,index){return _c('div',{key:topic.id,staticClass:"mx-xl-5 hover-linear-gradient",class:{
      'bg-read': topic.is_read && !_vm.isFromHome,
      'bg-unread': !topic.is_read,
    }},[_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary",class:{ 'd-xl-none': !_vm.isFromHome }}),_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"d-flex flex-column justify-content-between px-4",attrs:{"for":("checkbox-" + index)}},[_c('div',{staticClass:"flex-center"},[_c('div',{staticClass:"flex-fixed position-relative"},[(!_vm.isFromHome)?_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--7 w--7 h-xl--9 w-xl--9 me-1",attrs:{"src":topic.updater_avatar && !topic.allow_anonymous_comment
                  ? topic.updater_avatar
                  : _vm.noAvatar}}):_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--7 w--7 h-xl--9 w-xl--9 me-1",attrs:{"src":topic.group_avatar
                  ? topic.group_avatar
                  : _vm.isHomeCommon
                  ? _vm.commonTopicAvatar
                  : _vm.noGroupAvatar}}),(topic.emergency_contact)?_c('i',{staticClass:"fas fa-exclamation-circle fs-12 fs-xl-15 position-absolute end-0 bottom-0 text-danger bg-light rounded-pill"}):_vm._e()]),(topic.update_topic)?_c('i',{staticClass:"fas fa-ballot fs-24 fs-xl-30 px-1 color-icon-file"}):_c('i',{staticClass:"fas fa-comment-alt-lines text-primary fs-24 fs-xl-30 color-icon-file"})]),(!_vm.isCommonTopic && !_vm.disableCheckbox)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(topic.checked),expression:"topic.checked"}],staticClass:"form-check-input mt-1 ms-xl-1",attrs:{"id":("checkbox-" + index),"type":"checkbox"},domProps:{"checked":Array.isArray(topic.checked)?_vm._i(topic.checked,null)>-1:(topic.checked)},on:{"change":[function($event){var $$a=topic.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(topic, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(topic, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(topic, "checked", $$c)}},_vm.handleCheckItem]}}):_vm._e()]),(
          topic.status_company_create == 'stop' ||
            topic.status_company_create == 'leave'
        )?_c('router-link',{staticClass:"py-1 w-100 d-xl-flex justify-content-xl-between py-xl-4 button-border-radius",attrs:{"to":''}},[_c('div',{staticClass:"content pe-13 mb-1 w-xl-50"},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(topic.title),expression:"topic.title",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.truncateText(topic.title))+" ")])]),_c('div',{staticClass:"overflow-hidden h--8 pre-wrap-all"},[(topic.content)?_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 all-child-reset",domProps:{"innerHTML":_vm._s(topic.content)}}):_c('div',{staticClass:"text-secondary-tint-less"},[_vm._v(" "+_vm._s(_vm.$t("common.message.topic_no_content"))+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-xl-between flex-1"},[(!topic.allow_anonymous_comment)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.renderUpdater(topic)),expression:"renderUpdater(topic)",modifiers:{"hover":true,"top":true}}],staticClass:"updater-name text-line-clamp-1",class:{
                'text-secondary-tint-less': topic.user_active_status,
              }},[_vm._v(" "+_vm._s(_vm.renderUpdater(topic))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("groups.new_feed.anonymous")))]),_c('span',{staticClass:"flex-fixed px-3"},[_vm._v(" "+_vm._s(_vm.formatDateWithDays(topic.update_at))+" ")]),(_vm.isDraft)?_c('div',{staticClass:"d-flex d-xl-block flex-1 flex-xl-none justify-content-end"},[_c('span',{staticClass:"text-line-clamp-2"},[_vm._v(" "+_vm._s(((_vm.$t( "groups.new_feed.post_date_time" )) + ": " + (_vm.formatDateWithDays(topic.delivery_date))))+" ")])]):_vm._e()])])]):_c('router-link',{staticClass:"py-1 w-100 cursor-pointer d-xl-flex justify-content-xl-between py-xl-4 button-border-radius",attrs:{"to":_vm.jumpToTopicDetailPage(index, false)}},[_c('div',{staticClass:"content pe-13 mb-1 w-xl-50"},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(topic.title),expression:"topic.title",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.truncateText(topic.title))+" ")])]),_c('div',{staticClass:"overflow-hidden h--8 pre-wrap-all"},[(topic.content)?_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 all-child-reset",domProps:{"innerHTML":_vm._s(topic.content)}}):_c('div',{staticClass:"text-secondary-tint-less"},[_vm._v(" "+_vm._s(_vm.$t("common.message.topic_no_content"))+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-xl-between flex-1"},[(!topic.allow_anonymous_comment)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.renderUpdater(topic)),expression:"renderUpdater(topic)",modifiers:{"hover":true,"top":true}}],staticClass:"updater-name text-line-clamp-1",class:{
                'text-secondary-tint-less': topic.user_active_status,
              }},[_vm._v(" "+_vm._s(_vm.renderUpdater(topic))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t("groups.new_feed.anonymous")))]),_c('span',{staticClass:"flex-fixed px-3"},[_vm._v(" "+_vm._s(_vm.formatDateWithDays(topic.update_at))+" ")]),(_vm.isDraft)?_c('div',{staticClass:"d-flex d-xl-block flex-1 flex-xl-none justify-content-end"},[_c('span',{staticClass:"text-line-clamp-2"},[_vm._v(" "+_vm._s(((_vm.$t( "groups.new_feed.post_date_time" )) + ": " + (_vm.formatDateWithDays(topic.delivery_date))))+" ")])]):_vm._e()])])]),(!_vm.isDraft)?_c('div',{staticClass:"minw--33"},[(!topic.is_read)?_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light button-border-radius text-nowrap",on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.handleMarkRead(index)}]}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_mark_as_read"))+" ")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary",class:{ 'd-xl-none': _vm.isFromHome }})])}),_c('ModalError',{attrs:{"id":'modal-error-list-topic',"errorMess":_vm.$t('common.message.uncatch_error')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }